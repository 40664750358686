$creme: #f7f4ed;
$creme-100: #FFFDFA;
$doce-de-leite: #695c4e;
$amazonia: #4e462f;
$tijolo: #743d21;

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here
$body-bg: $creme-100;
$body-color: $amazonia;

$enable-dark-mode: false;

// 3. Include remainder of required Bootstrap stylesheets
@import "../node_modules/bootstrap/scss/variables";
// @import "../node_modules/bootstrap/scss/variables-dark";

// 4. Include any default map overrides here
$custom-colors: (
  "creme": #f7f4ed,
  "doce-de-leite": #695c4e,
  "amazonia": #4e462f,
  "tijolo": #743d21
);

$theme-colors: map-merge($theme-colors, $custom-colors);

// 5. Include remainder of required parts
@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/root";

// 6. Optionally include any other parts as needed
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";
// Layout & components
@import "../node_modules/bootstrap/scss/tables";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/transitions";
@import "../node_modules/bootstrap/scss/dropdown";
@import "../node_modules/bootstrap/scss/button-group";
@import "../node_modules/bootstrap/scss/nav";
@import "../node_modules/bootstrap/scss/navbar";
@import "../node_modules/bootstrap/scss/card";
@import "../node_modules/bootstrap/scss/accordion";
@import "../node_modules/bootstrap/scss/breadcrumb";
@import "../node_modules/bootstrap/scss/pagination";
@import "../node_modules/bootstrap/scss/badge";
@import "../node_modules/bootstrap/scss/alert";
@import "../node_modules/bootstrap/scss/progress";
@import "../node_modules/bootstrap/scss/list-group";
@import "../node_modules/bootstrap/scss/close";
@import "../node_modules/bootstrap/scss/toasts";
@import "../node_modules/bootstrap/scss/modal";
@import "../node_modules/bootstrap/scss/tooltip";
@import "../node_modules/bootstrap/scss/popover";
@import "../node_modules/bootstrap/scss/carousel";
@import "../node_modules/bootstrap/scss/spinners";
@import "../node_modules/bootstrap/scss/offcanvas";
@import "../node_modules/bootstrap/scss/placeholders";

@import "../node_modules/bootstrap/scss/helpers";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "../node_modules/bootstrap/scss/utilities/api";

// 8. Add additional custom code here
div.content-container {
  display: flex;
  // justify-content: center;
  flex-flow: column;
  align-items: center;
}

div#projects,
div#contact {
  width: 100%;
}

@include media-breakpoint-down(md) {
  .card {
    border-bottom: none;
    border-right: none;
    border-left: none;
    border-radius: 0px;
  }

  .project-details {

    .project-title,
    .project-description,
    .project-note {
      padding-left: .75em;
      padding-right: .75em;
    }
  }
}

.card {
  max-width: 1200px;

  &:first-of-type {
    margin-top: 0px;
  }

  &:last-of-type {
    margin-bottom: 0px;
  }

  margin-top: 16px;
  margin-bottom: 16px;
}

.project-item-wrapper {
  height: 300px;
  max-height: 28vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
  position: relative;

  &:nth-child(odd) {
    a {
      .project-title {
        right: 0;
        border-radius: 8px 0 0 8px;
      }
    }
  }

  a {
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;

    overflow: hidden;

    &:hover {
      .project-title {
        background-color: transparent;
        backdrop-filter: blur(.15em);
      }

      .project-image-wrapper {
        transform: scale(1.25);

        img {
          filter: blur(0) grayscale(0%);
        }
      }
    }

    .project-title {
      display: block;
      position: absolute;
      color: $white;
      background-color: rgba(0, 0, 0, 0.45);
      text-decoration: none;
      font-weight: bold;
      text-transform: uppercase;
      z-index: 15;

      padding: 1em;
      border-radius: 0 8px 8px 0;
      background-color: none;
      backdrop-filter: blur(0);
      // animation stuff
      transition: all .2s ease-in-out;
    }

    .project-image-wrapper {
      display: flex;
      height: 100%;
      // animation stuff
      transition: all .2s ease-in-out;
      transform: scale(1.05);

      img {
        // animation stuff
        transition: all .3s ease-in-out;

        object-position: 50% 50%;
        width: 100%;
        object-fit: cover;
        filter: blur(.1em) grayscale(80%);
      }
    }
  }
}

div.projects-page {
  .project-item {
    height: 300px;
    max-height: 28vh;
    display: flex;
    flex-flow: column;
    justify-content: center;
    position: relative;
    &:nth-child(even) {
      a {
        .project-title {
          right: 0;
          border-radius: 8px 0 0 8px;
        }
      }
    }
    a {
      height: 100%;
      display: flex;
      flex-flow: column;
      justify-content: center;
      overflow: hidden;

      .project-title {
        display: block;
        position: absolute;
        color: $white;
        background-color: rgba(0, 0, 0, 0.45);
        text-decoration: none;
        font-weight: bold;
        text-transform: uppercase;
        z-index: 15;

        padding: 1em;
        border-radius: 0 8px 8px 0;
        background-color: none;
        backdrop-filter: blur(0);
        // animation stuff
        transition: all .2s ease-in-out;
      }

      .project-image-wrapper {
        display: flex;
        height: 100%;
        // animation stuff
        transition: all .2s ease-in-out;
        transform: scale(1.05);

        img {
          // animation stuff
          transition: all .3s ease-in-out;

          object-position: 50% 50%;
          width: 100%;
          object-fit: cover;
          filter: blur(.1em) grayscale(90%);
        }
      }
    }
  }
  .project-item:not(.unreleased) a{
    &:hover {
        .project-title {
          background-color: transparent;
          backdrop-filter: blur(.15em);
        }
    
        .project-image-wrapper {
          transform: scale(1.25);
    
          img {
            filter: blur(0) grayscale(0%);
          }
        }
      }
  }
  .project-item.unreleased a{
    pointer-events: none;
  }
}

.project-details {
  .project-item {
    .image-wrapper {
      height: 200px;
      max-height: 20vh;
      display: flex;
      align-items: center;
      overflow: hidden;

      width: 100%;
      padding: 0;
      border-radius: 0;
      border: 0;

      img {
        object-position: 50% 50%;
        object-fit: cover;
      }
    }
  }
}

.modal-project-details {
  max-height: 80vh;
}

.wide-img{
  height: 100%;
}