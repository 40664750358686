// @import '~bootstrap/scss/bootstrap';
@import '../scss/custom-styling';

body {
  margin: 0;
  font-family: 'League Spartan', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.about-pic {
  height: 45vh;
}

.text-justify {
  text-align: justify;
}

.w-350 {
  width: 350px;
}